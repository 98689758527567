<!--src/Widget/CodeEditor.svelte-->
<!--
// Scripts
---------------->
<script>
// @ts-nocheck
    import { onMount, onDestroy } from 'svelte';
    import CodeMirror from 'codemirror';
    import 'codemirror/lib/codemirror.css';
    import '../Utils/codemirrorImports.js';

    import { mapLanguageToMode } from '../Utils/languageModes.js';
    
    export let code = '';
    export let showLineNumbers = false;
    export let winTheme = '';
    export let winLanguage = '';
    export let winFont = '';

    let editor;
    let editorContainer;

    onMount(() => {
        editor = CodeMirror(editorContainer, {
            value: code,
            mode: mapLanguageToMode(winLanguage),
            lineNumbers: showLineNumbers,
            theme: winTheme,
            readOnly: true,
        });

        const decodedFont = decodeURIComponent(winFont).replaceAll('+', ' ');
        editor.getWrapperElement().style.fontFamily = `"${decodedFont}", monospace`;

        // Set the height of the CodeMirror editor to auto
        editor.getWrapperElement().style.height = 'fit-content';

        // To ensure the content dictates the height, adjust the scroll height dynamically
        editor.setSize(null, 'fit-content');

        editor.refresh();
        
    });

    onDestroy(() => {
        if (editor && typeof editor.toTextArea === 'function') {
            editor.toTextArea();
        }
    });

    $: if (editor && winLanguage) {
        editor.setOption('mode', mapLanguageToMode(winLanguage));
    }

    $: if (editor && winFont) {
        const decodedFont = decodeURIComponent(winFont).replaceAll('+', ' ');
        editor.getWrapperElement().style.fontFamily = `"${decodedFont}", monospace`;
        editor.refresh();
    }
</script>

<!--
// HTML
---------------->
<div bind:this={editorContainer} class="editor-container"></div>