<script>
export let appBaseUrl;
</script>

<div class="cb-bg-white">
    <div class="cb-@container cb-mx-auto cb-max-w-3xl cb-px-6 lg:cb-px-8">
        <div class="cb-w-full cb-p-4 cb-bg-white cb-border cb-border-gray-200 cb-rounded-lg cb-shadow cb-sm:p-8">
            <a href="{appBaseUrl}">
                <span class="cb-sr-only">Coblox</span>
                <img class="cb-h-8 cb-w-auto cb-mb-4" src="{appBaseUrl}/img/logos/coblox-logo.png" alt="{import.meta.env.VITE_APP_NAME} logo">
            </a>
            <h5 class="cb-mb-2 cb-text-2xl cb-font-bold cb-text-gray-900">Oops! We could not load this Coblox.</h5>
            <p class="cb-mb-5 cb-text-base cb-text-gray-500 cb-sm:text-lg">If you are the owner of this CoBlox, please login to update your account.</p>
            <a href="{appBaseUrl}/login" class="cb-whitespace-nowrap cb-inline-flex cb-items-center cb-justify-center cb-bg-teal-600 cb-px-4 cb-py-2 cb-border cb-border-transparent cb-rounded-md cb-shadow-sm cb-text-base cb-font-medium cb-text-white hover:cb-bg-teal-500"> Sign In </a>
        </div>
    </div>
</div>

