// src/Stores/apiData.js
import { writable, derived } from 'svelte/store';
import { getBaseUrl } from '../Utils/global';
const baseUrl = getBaseUrl();

// Stores data for all widgets, keyed by their UUID
const allWidgetsData = writable({});

// Function to initialize widget data
function createWidgetData(uuid) {
    const { subscribe, set, update } = writable({
        loading: true,
        error: null,
        data: null,
        globalSettings: {
            winThem: 'oneDark',
            winFont: 'JetBrains+Mono',
            background: {},
            window: {},
            uuid: uuid,
            enabled: true,
            on_trial: true,
            subscribed: false,
            past_due: false,
            name: '',
        },
    });

	function delay(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

    return {
        subscribe,
        set,
        update,
        fetchData: async () => {
            set({ loading: true, error: null, data: null }); // Initialize loading state
            await delay(200); // Simulate delay
            try {
                // const response = await fetch(`${baseUrl}/api/widget/9b907462-b544-4f87-be54-ddf649948082`);
                const response = await fetch(`${baseUrl}/api/widget/${uuid}`);
                if (!response.ok) {
                    const errMsg = `Error ${response.status}: ${response.statusText || 'Widget could not be loaded.'}`;
                    set({ loading: false, error: errMsg, data: null });
                }
                const data = await response.json();
                set({ loading: false, error: null, data: data });
            } catch (error) {
                console.log(error.message);
                set({ loading: false, error: error.message, data: null });
            }
        },
    };
}

// A derived store to access specific widget's data by UUID
export const getWidgetData = (uuid) => {
    let widgetData;
    allWidgetsData.update((widgets) => {
        if (!widgets[uuid]) {
            widgets[uuid] = createWidgetData(uuid);
            widgetData = widgets[uuid];
            widgetData.fetchData(); // Fetch data for the new widget
        } else {
            widgetData = widgets[uuid];
        }
        return widgets;
    });
    return widgetData; // Return the store for the specific widget
};