<!--src/Widget/Tools.svelte-->
<!--
// Scripts
---------------->
<script>
import { tick } from 'svelte';
import { writable } from 'svelte/store';

export let code;
export let winLightTheme;
export let winTabColor;
export let winTabs;
let copied = writable(false); 

async function copyToClipboard() {
    try {
        await navigator.clipboard.writeText(code);
        copied.set(true);
        await tick(); // Ensure UI updates to reflect the copied state
        setTimeout(() => {
            copied.set(false);
        }, 2000); // Reset copied state after 2 seconds
    } catch (err) {
        console.error('Failed to copy:', err);
    }
}
</script>

<!--
// HTML
---------------->
<div class="cb-z-10 cb-absolute {winTabs === '1' ? 'cb-top-12' : 'cb-top-1'} cb-right-1 cb-p-2">
    <button 
        on:click={copyToClipboard} 
        class="{winLightTheme === '1' ? 'cb-text-gray-300' : 'cb-text-gray-500 cb-border-gray-700'} cb-border cb-opacity-50 hover:cb-opacity-100 cb-rounded-md cb-px-1 cb-py-0.1"
        style="background-color:{winTabColor}"
        class:cb-text-green-600={$copied}
        >               
        <svg aria-hidden="true" width="32" height="32" viewBox="0 0 32 32" fill="none" class="cb-h-8 cb-w-8 cb-ml-0.5 cb-md:ml-0 cb-stroke-current cb-transform group-hover:cb-rotate-[-10deg] cb-transition"
            style:transform={$copied ? 'rotate(-12deg)' : 'rotate(0deg)'}>
            <path d="M12.9975 10.7499L11.7475 10.7499C10.6429 10.7499 9.74747 11.6453 9.74747 12.7499L9.74747 21.2499C9.74747 22.3544 10.6429 23.2499 11.7475 23.2499L20.2475 23.2499C21.352 23.2499 22.2475 22.3544 22.2475 21.2499L22.2475 12.7499C22.2475 11.6453 21.352 10.7499 20.2475 10.7499L18.9975 10.7499" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M17.9975 12.2499L13.9975 12.2499C13.4452 12.2499 12.9975 11.8022 12.9975 11.2499L12.9975 9.74988C12.9975 9.19759 13.4452 8.74988 13.9975 8.74988L17.9975 8.74988C18.5498 8.74988 18.9975 9.19759 18.9975 9.74988L18.9975 11.2499C18.9975 11.8022 18.5498 12.2499 17.9975 12.2499Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M13.7475 16.2499L18.2475 16.2499" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M13.7475 19.2499L18.2475 19.2499" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            {#if $copied}
            <g class="cb-transition-opacity">
                <path d="M15.9975 5.99988L15.9975 3.99988" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M19.9975 5.99988L20.9975 4.99988" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M11.9975 5.99988L10.9975 4.99988" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </g>
            {/if}
        </svg>
    </button>
</div>