<!-- App.svelte-->
<!--
// Scripts
---------------->
<script>
// @ts-nocheck
import { onMount } from 'svelte';
import { fade } from 'svelte/transition';
import autoAnimate from '@formkit/auto-animate';
import { getBaseUrl } from './Utils/global';
import { getWidgetData } from './Stores/apiData';
import { trackView } from './viewTracker';
import Spinner from './Components/Spinner.svelte';
import Error from './Components/Error.svelte';
import NoAccess from './Components/NoAccess.svelte';
import Tools from './Widget/Tools.svelte';
import CodeEditor from './Widget/CodeEditor.svelte';
import Branding from './Components/Branding.svelte';

const baseUrl = getBaseUrl();
export let uuid;

export let activeTab = null; // This is set based on the activeTab prop passed from main.js
let activeTabId; // Used for setting the active state in the view
let activeEditorContent = '';
let activeLanguage = '';
let activeLineNumbers = '';
let activeMaxHeight = '';

onMount(() => {
	trackView(uuid, baseUrl);
    // Initially, activeTab is either null or a specific index
    // Ensure we set an active tab based on this
    if (widgetData.editors?.length) {
        setActiveTabBasedOnProp();
    }
});

const widgetDataStore = getWidgetData(uuid);
// Initialize widgetData with a default structure to prevent errors
let widgetData = { loading: true, error: null, editors: [], globalSettings: {} };

widgetDataStore.subscribe(value => {
    widgetData = value.data ? value.data : widgetData;
    if (widgetData.editors?.length) {
        setActiveTabBasedOnProp();
    }
});

$: activeTabId = widgetData.editors?.[activeTab]?.id;

function setActiveTabBasedOnProp() {
    // If activeTab is not set (null) or out of bounds, default to the first tab
    if (activeTab === null || activeTab >= widgetData.editors.length || activeTab < 0) {
        activeTab = 0; // Default to the first tab
    }
    setActiveTab(widgetData.editors[activeTab]);
}

function setActiveTab(editor) {
    if (!editor) return; // Guard clause if editor is undefined
    activeTab = widgetData.editors.findIndex(ed => ed.id === editor.id); // Ensure activeTab index is updated
    activeTabId = editor.id; // Update activeTabId for use in the view
    activeEditorContent = editor.code;
    activeLanguage = editor.language;
    activeLineNumbers = editor.line_numbers;
    activeMaxHeight = editor.max_height;
}
</script>


<!--
// HTML
---------------->
{#if widgetData.loading}
    <Spinner />
{:else if widgetData.error}
    <Error appBaseUrl={baseUrl} error={widgetData.error} />
{:else if widgetData.enabled && !widgetData.past_due }

<div transition:fade={{ delay: 0, duration: 300 }}>
	<div class="cb-{widgetData.background.padding} cb-{widgetData.background.border_radius}" style="background-color: {widgetData.background.visible === '0' ? 'transparent' : widgetData.background.color};font-family: Inter var, system-ui, -apple-system, sans-serif;">
		<!-- Window -->
		<div class="cb-{widgetData.window.border_radius} cb-{widgetData.window.shadow_size} cb-overflow-hidden cb-relative">
			<!-- Reflection -->
			{#if widgetData.window.reflection === '1'}
			<svg class="cb-absolute cb-left-0 cb-top-0 cb-w-3/6 cb-z-10 cb-pointer-events-none" viewBox="0 0 100 172" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 0H100L47 172H0V0Z" fill="url(#paint0_linear_47_2)"></path>
				<defs>
					<linearGradient id="paint0_linear_47_2" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
						<stop stop-color="white" stop-opacity=".035"></stop><stop offset="1" stop-color="white" stop-opacity="0"></stop>
					</linearGradient>
				</defs>
			</svg>
			{/if}
			<!-- Browser bar -->
			{#if widgetData.window.tabs === '1'}
			<div class="cb-h-11 cb-flex cb-px-2 cb-relative" style="background-color:{widgetData.window.tab_color}cc;"> 
				<div class="cb-flex cb-items-center cb-space-x-1.5 cb-mr-4 cb-mt-1 cb-ml-2">
					<span class="cb-w-3 cb-h-3 cb-rounded-full cb-bg-red-400"></span>
					<span class="cb-w-3 cb-h-3 cb-rounded-full cb-bg-yellow-400"></span>
					<span class="cb-w-3 cb-h-3 cb-rounded-full cb-bg-green-400"></span>
				</div>
				<div class="cb-flex cb-items-end cb-overflow-y-auto cb-scrollbar-none cb-snap-x cb-snap-mandatory cb-font-normal">
					{#each widgetData.editors as editor, i (editor.id)}
					<button 
						on:click={() => setActiveTab(editor)}
						style="background-color: {activeTabId === editor.id ? widgetData.window.tab_color : 'transparent'}"
						class="cb-space-x-2 cb-snap-start cb-cursor-pointer cb-text-gray-200 cb-px-3 cb-py-2.5 cb-rounded-t-lg cb-flex cb-justify-between cb-items-center cb-transition-colors cb-duration-150 cb-bg-gray-900 focus:cb-ring-0 focus:cb-outline-none"
						>
						<!-- Tab Icon -->
						{#if widgetData.window.tab_icon === '1'}
							{#if editor.language === 'markdown' || editor.language === 'yaml' || editor.language === 'rust' || editor.language === 'plaintext'}
							<svg class="cb-h-3.5 cb-w-3.5" fill="{ widgetData.window.light_theme === '1' ? '#1f2937' : '#e5e7eb' }" viewBox="0 0 {editor.language === 'plaintext' ? '24 24' : '128 128'}">{@html editor.language_icon }</svg>
							{:else}
								<img class="cb-h-3.5 cb-w-3.5" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/{editor.language_icon}" alt="{editor.language}"/>
							{/if}
						{/if}
						<!-- Tab Name -->
						<span class="tabinput cb-whitespace-nowrap {widgetData.window.light_theme === '1' ? 'cb-text-gray-800' : 'cb-text-gray-200'} cb-text-xs">
							{editor.tab_title}
						</span>
					</button>
					<!-- Conditionally display the divider -->
					{#if i < widgetData.editors.length - 1 && (widgetData.editors[i + 1].id !== activeTabId && editor.id !== activeTabId)}
						<div class="cb-w-px cb-h-4 cb-mb-2.5 {widgetData.window.light_theme === '1' ? 'cb-bg-gray-300' : 'cb-bg-gray-600'}"></div>
					{/if}
					{/each}

				</div>
			</div>
			{/if}
			<!--- Tools / Copy -->
			{#if widgetData.window.copy_btn === '1'}
				<Tools 
					code={activeEditorContent} 
					winLightTheme={widgetData.window.light_theme}
					winTabColor={widgetData.window.tab_color}
					winTabs={widgetData.window.tabs}
				/>
			{/if}
			<!-- Editor Wrapper -->
			<div class="cb-py-6 cb-pr-6 -cb-mt-px cb-overflow-y-auto cb-{widgetData.window.font_size} cb-{widgetData.window.font_weight} {activeLineNumbers ? 'cb-pl-3': 'cb-pl-6'}" 
				style="max-height: {activeMaxHeight != 0 ? `${activeMaxHeight}px` : ''}; background-color: {widgetData.window.tab_color};"
			>
				<div use:autoAnimate>
					<!-- Editor -->
					{#key activeTab}
						{#if activeEditorContent && activeLanguage}
							<CodeEditor 
								code={activeEditorContent} 
								winLanguage={activeLanguage}
								showLineNumbers={activeLineNumbers}
								winTheme={widgetData.window.theme}
								winFont={widgetData.window.font}
							/>
						{/if}
					{/key}
				</div>
			</div>
			<!-- Watermark -->
			{#if widgetData.window.branding === '1'}
				<Branding 
					appBaseUrl={baseUrl}
					winLightTheme={widgetData.window.light_theme}
				/>
			{/if}
		</div>
	</div>
</div>
{:else}
<NoAccess appBaseUrl={baseUrl}
/>
{/if}


<!--
// Styles
---------------->
<style global>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>