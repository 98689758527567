// src/Utils/languageModes.js
function mapLanguageToMode(language) {
    switch (language) {
        case 'c':
        case 'cpp': // for C++
        case 'csharp': // for C#
        case 'java':
        case 'scala':
        case 'kotlin':
            return 'text/x-csrc';
        case 'css':
            return 'text/css';
        case 'dart':
            return 'application/dart';
        case 'go':
            return 'text/x-go';
        case 'groovy':
            return 'text/x-groovy';
        case 'html':
            return 'text/html';
        case 'javascript':
        case 'react':
        case 'solidjs':
        case 'svelte':
        case 'angular':
            return 'text/javascript';
        case 'julia':
            return 'text/x-julia';
        case 'markdown':
            return 'text/x-markdown';
        case 'perl':
            return 'text/x-perl';
        case 'php':
            return 'text/x-php';
            // return 'application/x-httpd-php';
        case 'plaintext':
            return 'text/plain';
        case 'python':
            return 'text/x-python';
        case 'r':
            return 'text/x-rsrc';
        case 'ruby':
            return 'text/x-ruby';
        case 'rust':
            return 'text/x-rustsrc';
        case 'sass':
            return 'text/x-sass';
        case 'sql':
            return 'text/x-sql';
        case 'swift':
            return 'text/x-swift';
        case 'vb': // for Visual Basic
            return 'text/x-vb';
        case 'vue':
            return 'text/x-vue';
        case 'xml':
            return 'application/xml';
        case 'yaml':
            return 'text/x-yaml';
        default:
            return ''; // Fallback mode
    }
}

export { mapLanguageToMode };