//main.js
import App from './App.svelte'


        // const app = new App({
        // target: document.getElementById('app'),
        // })

        // export default app


import { getBaseUrl } from './utils/global';
const baseUrl = getBaseUrl();

class WidgetEmbed extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    async fetchWidgetConfig(uuid) {
        try {
            
            // const response = await fetch(`${baseUrl}/api/widget/9b907462-b544-4f87-be54-ddf649948082`);
            const response = await fetch(`${baseUrl}/api/widget/${uuid}`);
            if (!response.ok) {
                throw new Error('Widget could not be loaded.');
            }
            const config = await response.json();
            return config;
        } catch (error) {
            console.error("Failed to fetch widget config:", error);
            return null; // Handle error appropriately
        }
    }

    async connectedCallback() {
        const uuid = this.getAttribute('data-uuid');
        const activeTabAttr = this.getAttribute('data-active-tab');
    
        let activeTab = null; // Default to null if not provided or if parsing fails

        // Parse and validate the 'data-active-tab' attribute
        if (activeTabAttr !== null) {
            const parsedTab = parseInt(activeTabAttr, 10);
            if (!isNaN(parsedTab) && parsedTab > 0) { // Ensure it's a positive number
                activeTab = parsedTab - 1; // Adjust to zero-based index
            } else {
                // console.warn("Invalid 'data-active-tab' value. It should be a positive integer.");
            }
        }

        // Fetch widget configuration
        const config = await this.fetchWidgetConfig(uuid);
        if (config && config.window && config.window.font) {
            const fontName = config.window.font.replace(/\s+/g, '+');
            this.loadGoogleFont(fontName);
        }

        const style = document.createElement('style');
        style.textContent = `@import '${baseUrl}/embed/embedCss.css';`;
        this.shadowRoot.appendChild(style);

        const appContainer = document.createElement('div');
        this.shadowRoot.appendChild(appContainer);

        // Initialize the Svelte app with the uuid and any other necessary props
        new App({
            target: appContainer,
            props: {
                uuid,
                activeTab,
                winFont: config ? config.window.font : null,
                // Other config props as needed
            }
        });
    }
    

    // Load google font in the doc <head>
    loadGoogleFont(fontName) {
        // Preconnect to Google Fonts
        const preconnectGoogleApis = document.createElement('link');
        preconnectGoogleApis.href = 'https://fonts.googleapis.com';
        preconnectGoogleApis.rel = 'preconnect';
        document.head.appendChild(preconnectGoogleApis);
    
        const preconnectGstatic = document.createElement('link');
        preconnectGstatic.href = 'https://fonts.gstatic.com';
        preconnectGstatic.rel = 'preconnect';
        preconnectGstatic.crossOrigin = ''; // Note: `crossorigin` attribute is sometimes needed
        document.head.appendChild(preconnectGstatic);
    
        // Font link
        const link = document.createElement('link');
        link.href = `https://fonts.googleapis.com/css2?family=${fontName}:wght@400;700&display=swap`;
        link.rel = 'stylesheet';
        document.head.appendChild(link);
    }

}

window.customElements.define(import.meta.env.VITE_EMBED_ELEMENT, WidgetEmbed);