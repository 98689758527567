<!--src/Components/Error.svelte-->
<script>
    export let appBaseUrl;
    export let error;
</script>

<div class="cb-bg-white">
    <div class="cb-@container cb-mx-auto cb-max-w-3xl cb-px-6 lg:cb-px-8">
        <div class="cb-w-full cb-p-4 cb-bg-white cb-border cb-border-gray-200 cb-rounded-lg cb-shadow cb-sm:p-8">
            <a href="{appBaseUrl}">
                <span class="cb-sr-only">CoBlox</span>
                <img class="cb-h-8 cb-w-auto cb-mb-4" src="{appBaseUrl}/img/logos/coblox-logo.png" alt="{import.meta.env.VITE_APP_NAME} logo">
            </a>
            <h5 class="cb-mb-2 cb-text-2xl cb-font-bold cb-text-gray-900">Oops! There seems to be a problem.</h5>
            {#if error}
            <p class="cb-mb-5 cb-text-base cb-text-gray-500 cb-sm:text-lg">Error: {error}</p>
            {/if}
            <p class="cb-mb-5 cb-text-base cb-text-gray-500 cb-sm:text-lg">Please login to your account or email <span class="cb-text-teal-700">hello@coblox.io</span> for help.</p>
        </div>
    </div>
</div>
