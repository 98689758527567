<script>
    export let appBaseUrl;
    export let winLightTheme;
    let isHovering = false;
</script>

<div class="cb-absolute cb-bottom-4 cb-right-4">
    <a href="{appBaseUrl}" target="_blank">
        <img class="cb-h-4 cb-cursor-pointer" 
            src="{appBaseUrl}/img/logos/{winLightTheme === '1' ? 'coblox-logo-watermark-dk.png' : 'coblox-logo-watermark-lt.png'}" 
            alt="{import.meta.env.VITE_APP_NAME} logo"
            class:cb-opacity-100={isHovering}
            class:cb-opacity-50={!isHovering}
            on:mouseenter={() => isHovering = true} 
            on:mouseleave={() => isHovering = false}
        />
    </a>
</div>