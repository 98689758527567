// view-tracker.js
export function trackView(uuid, baseUrl, limit = 5, period = 3600000) { // 3600000 ms = 1 hour
    const visitedKey = 'visited_' + uuid;
    const timestampKey = 'timestamps_' + uuid;
    const now = Date.now();

    // Try to fetch the timestamp array from localStorage, or initialize it as empty
    const timestamps = JSON.parse(localStorage.getItem(timestampKey)) || [];

    // Filter out timestamps outside the rate limit period (now 1 hour)
    const recentTimestamps = timestamps.filter(timestamp => now - timestamp < period);

    if (recentTimestamps.length < limit) {
        // Log the view
        // console.log("Logging view...");

        const visitorData = {
            domain: window.location.hostname,
            referrer: document.referrer || 'Direct / None',
            user_agent: navigator.userAgent
        };

        fetch(`${baseUrl}/api/view-count/${uuid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(visitorData)
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data.message);
            // Add the current timestamp to the array and save it
            recentTimestamps.push(now);
            localStorage.setItem(timestampKey, JSON.stringify(recentTimestamps));
            localStorage.setItem(visitedKey, 'true'); // Consider if you still need this
        })
        .catch(error => {
            console.error("Error logging view:", error);
        });
    } else {
        console.log("Rate limit exceeded, skipping data send.");
    }
}